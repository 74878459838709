import React from 'react'
import Head from 'next/head'
import { renderSlices } from '../components/page-slices'
import { RichText } from '../lib/prismic'
import { useLayout } from '../lib/layoutContext'
import { getIndexPageStaticProps } from '../page-services/index/index-page-data-service'

function Index({ page, language, additionalData }) {
  const layout = useLayout()

  return (
    <>
      <Head>
        {page.meta_title && <title>{RichText.asText(page.meta_title)}</title>}
        {page.meta_title && (
          <meta
            property='og:title'
            content={RichText.asText(page.meta_title)}
          />
        )}
        {page.meta_description && (
          <meta
            name='description'
            content={RichText.asText(page.meta_description)}
          />
        )}
        {page.meta_description && (
          <meta
            property='og:description'
            content={RichText.asText(page.meta_description)}
          />
        )}
        {page.og_image && (
          <meta
            name='og:image'
            content={page.og_image.url}
            alt={page.og_image.alt || ''}
          ></meta>
        )}
      </Head>
      {renderSlices(page.body, layout, language, additionalData)}
    </>
  )
}

export async function getStaticProps({ params, locale }) {
  return await getIndexPageStaticProps(params, locale)
}

export default Index
